let _LEASING_CALCULATOR = function() {

	let $this;
	let SELECTOR_GLOBAL_WRAPPER = ".styled_win_content_container";
	$this = {
		calculator_window: function() {
			let $calculator_window;
			$calculator_window = {
				fwwin: null,
				open: function(input_data) {
					$calculator_window.fwwin = fw_WIN.create({
						lock_page: true,
						block_mode: true,
						top_offset: 50,
						offset: 0,
						content: '',
						title: "Расчет лизинга",
						container_class: "styled_form_container",
						content_class: "leasing_calculator_win_content",
						width: 900,
						without_padding: true
					});
					$this.calculator_window.show($calculator_window.fwwin.content, input_data);
				},
				show: function (area, input_data) {
					// Догрузка AJAX
					let uid = _LEASING_CALCULATOR.calculator_window.initWindow();
					fw_MOJAX.send({
						to: area,
						user_data: {
							uid: uid
						},
						url: "/ajax/calculator/showcalculatorcontainer/",
						user_success: function(data, user_data){
							$this.calculator.load(user_data['uid'], null, input_data);
						},
						overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
						overlay_to: area
					});
				},
				initWindow: function () {
					let uid;
					let url_hash = location.hash.replace(/\/\?.*/, "")
					let searchedStr = '#calc';
					if (url_hash.substr(0, searchedStr.length) == searchedStr) {
						let regexp = /\#calc\[([0-9]+)\].*/g;
						let result = regexp.exec(url_hash);
						if (result !== null && result[1] !== undefined) {
							uid = result[1];
							document.getElementById("leasing_calc").scrollIntoView();
						}
						else {
							uid = 0;
						}
					}
					return uid;
				}
			};
			return $calculator_window;
		}(),
		calculator: function() {
			let SELECTOR_CALCULATOR_CONTENT = ".calculator-block";
			let SELECTOR_RESULT_CONTENT = ".result";
			let SELECTOR_RARIO_BUTTONS = ".radio_buttons";
			let RB_ACTIVE_CLASS = "active";
			let $calculator;
			$calculator = {
				user_type_selector: function() {
					let SELECTOR_USER_TABS = "#user_type_tabs";
					let SELECTOR_INPUT_USER_TYPE = "#user_type_input";
					let CLASS_SELECTED_TAB = "selected";
					let $ut;
					let $user_types_info = {
						"fl": {
							name: "Физическое лицо",
							controller: "showorderformfl",
							code: "fl",
							id: "1"
						},
						"ul": {
							name: "Юридическое лицо",
							controller: "showorderformul",
							code: "ul",
							id: "2"
						}
					};
					function setValueInInput(value) {
						$(SELECTOR_INPUT_USER_TYPE).val(value);
					}
					$ut = {
						user_type: null,
						setUserType: function(type_code, alternative_selectors) {
							if (!$user_types_info.hasOwnProperty(type_code)) {
								console.log('Неверный тип клиента '+type_code);
								return false;
							}
							$ut.user_type = $user_types_info[type_code];
							setValueInInput($ut.user_type['id']);
							$calculator.sliders.replaceTermSlider(alternative_selectors);
							$this.result.clearResult();
							return true;
						},
						tabsClickEvent: function(item, alternative_selectors) {
							let type_code = $(item).data('user_type');
							$ut.setUserType(type_code, alternative_selectors);
							$ut.changeActiveTab(item, alternative_selectors);
							return false;
						},
						changeActiveTab: function(obj, alternative_selectors) {
							let selector_user_tabs;
							if (alternative_selectors) {
								selector_user_tabs = alternative_selectors['SELECTOR_USER_TABS'];
							} else {
								selector_user_tabs = SELECTOR_USER_TABS; 
							}
							$(selector_user_tabs).find('.'+CLASS_SELECTED_TAB).removeClass(CLASS_SELECTED_TAB);
							$(obj).addClass(CLASS_SELECTED_TAB);
						},
						initTabs: function(alternative_selectors, typeUser){
							let selector_user_tabs;
							if (alternative_selectors) {
								selector_user_tabs = alternative_selectors['SELECTOR_USER_TABS'];
							} else {
								selector_user_tabs = SELECTOR_USER_TABS;
							}
							$(selector_user_tabs).find('a').click(function (e) {
								$ut.tabsClickEvent(this, alternative_selectors);
								e.preventDefault();
							});
							if(typeUser){
								$(selector_user_tabs).find('a').removeClass('selected');
								$(selector_user_tabs).find('a[data-user_type='+typeUser+']').addClass('selected');	
							}
						}
					};
					return $ut;
				}(),
				load: function(uid, parent_wrapper, input_data) {
					input_data = input_data || {

							price: null,
							start_val: null,
							range: null
						};
					fw_MOJAX.send({
						to: parent_wrapper || SELECTOR_CALCULATOR_CONTENT,
						data: {
							uid: uid
						},
						url: "/ajax/calculator/showcalculator/",
						user_success: function(data, user_data){
							$calculator.user_type_selector.initTabs(null,input_data.user_type);
							$calculator.sliders.initPriceSlider(input_data.price || data['price']);
							$calculator.sliders.initTermSlider(input_data.start_val, input_data.range);
							$calculator.sliders.initPrepaymentSlider();
							$calculator.sliders.initBuyoutSlider();
							$calculator.initRadioButtons();
							/*$calculator.initSubmitButton();*/
							$calculator.user_type_selector.setUserType(input_data.user_type || "ul");
						},
						overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
						overlay_to: parent_wrapper || SELECTOR_CALCULATOR_CONTENT
					});
				},
				sliders: function() {
					let SELECTOR_PRICE_SLIDER = "#calculator_price_slider";
					let SELECTOR_PRICE_INPUT = "#calculator_price_input";
					let MIN_PRICE = 400000;
					let MAX_PRICE = 20000000;
					let DEFAULT_PRICE = 850000;
					
					let SELECTOR_TERM_SLIDER = "#calculator_term_slider";
					let SELECTOR_TERM_INPUT = "#calculator_term_input";
					let MIN_TERM = 6;
					let MAX_TERM = 60;
					let DEFAULT_TERM = 36;
					let MAX_FL_TERM = 60;

					let $sliders;
					$sliders = {



					};
					return $sliders;
				}(),
				initRadioButtons:function() {
					$(SELECTOR_RARIO_BUTTONS + ' input').change(
						function() {
							$(SELECTOR_RARIO_BUTTONS).find('.' + RB_ACTIVE_CLASS).removeClass(RB_ACTIVE_CLASS);
							$(this).closest('label').addClass(RB_ACTIVE_CLASS);
						}
					);
				},
				initSubmitButton:function() {
					/*let SELECTOR_SUBMIT_BUTTON = ".calculator_submit_action";
					$(SELECTOR_SUBMIT_BUTTON).click(
						function(){
							$this.result.getResult();
							$(this).trigger('gtm_calculator_submit');
						}
					);*/
				},
				hideCalculator:function() {
					$(SELECTOR_CALCULATOR_CONTENT).hide();
				},
				showCalculator:function() {
					$(SELECTOR_CALCULATOR_CONTENT).show();
					$(SELECTOR_RESULT_CONTENT).show();
					/*$('j-feedback-instead').show();*/
				},

				hideResultContent: function () {
					console.log('hideResultContent 1',$(SELECTOR_RESULT_CONTENT))
					$(SELECTOR_RESULT_CONTENT).hide();
				},
				showResultContent: function () {
					$(SELECTOR_RESULT_CONTENT).show();
				}
			}


			;
			return $calculator;
		}(),
		result:function(){
			let SELECTOR_RESULT_CONTENT = ".result";
			let SELECTOR_CALCULATOR_FORM = "#calculator_form";
			let SELECTOR_SHOW_ALL_BUTTONS = ".show_all_link";
			let SELECTOR_HIDDEN_ROW = ".hidden_calc_row";
			let SELECTOR_RESULT_HEADER = ".calculator_result";
			let SELECTOR_PRINT_LINK = ".calculator_footer .print_link";
			let SELECTOR_PRINT_FORM_CONTAINER = "#print_form_container";
			let OPENED_CLASS = "opened";
			let $result;
			let $input_data = {};
			$result = {
				setInputData:function(data){
					$input_data = data;
				},
				putInputDataIntoForm:function(form_selector) {
					for (let input_key in $input_data) {
						if ($input_data.hasOwnProperty(input_key)) {
							let input = $(form_selector).find('input[name='+input_key+']');
							input.val($input_data[input_key]);
						}
					}
				},
				initHiddenRowsButtons:function() {
					$(SELECTOR_SHOW_ALL_BUTTONS).off('click').on('.click', function(){
						if ($(SELECTOR_SHOW_ALL_BUTTONS + '.' + OPENED_CLASS).length) {
							$(SELECTOR_HIDDEN_ROW).hide();
							$(SELECTOR_SHOW_ALL_BUTTONS + '.' + OPENED_CLASS).removeClass(OPENED_CLASS).text("Показать все платежи");
						}
						else {
							$(SELECTOR_HIDDEN_ROW).show();
							$(SELECTOR_SHOW_ALL_BUTTONS).addClass(OPENED_CLASS).text("Скрыть платежи");
						}
					});
				},
				getResultBlockContent:function(){
					return $(SELECTOR_RESULT_HEADER).clone();
				},
				initResultFormSubmit:function() {
					let SELECTOR_SUBMIT_BUTTON = ".result_submit_action";
					$(SELECTOR_SUBMIT_BUTTON).click(
						function(){
							$this.orderForm.loadOrderForm();
							$(this).trigger('gtm_result_submit');
						}
					)
				},
				showResultError:function(){
					$(SELECTOR_RESULT_CONTENT).html(
						"<div class='calculator_header cf'><span class='global_error'>Произошла ошибка при расчете. Повторите попытку позже.</span></div>"
					);
				},
				scrollToResult:function(){
					if (fw_MAMMON.isMobileDevice()) {
						$('body,html').scrollTo($(SELECTOR_RESULT_CONTENT),500);
					}
					else {
						$('.fw_win_wrapper').scrollTo($(SELECTOR_RESULT_CONTENT),500);
					}
				},
				hideResult:function() {
					$('.j-feedback-instead').hide();
					$('.result_submit_action').hide();
					$('.js-back-link-button').show();
					$('.calculation-header').show();
					$('svg.icon.icon-black_arrow').show();
				},
				showResult:function() {
					$('.j-feedback-instead').show();
					$('.calculator-block').show();
					$('.result_submit_action').show();
					$('.js-back-link-button').hide();
					$('.calculation-header').hide();
					$('svg.icon.icon-black_arrow').hide();
				},
				clearResult:function() {
					$(SELECTOR_RESULT_CONTENT).hide();
					$(SELECTOR_RESULT_CONTENT).removeAttr("style");
					$('.result_submit_action').hide();
				},
				runPrintForm:function() {
					let form = document.createElement('form');
					form.method = "POST";
					form.action = "/calculator/print/";
					form.target = "_blank";
					let param_input = {};
					for (let key in $input_data) {
						if ($input_data.hasOwnProperty(key)) {
							param_input = $('<input name="'+key+'">');
							param_input.val($input_data[key]);
							$(form).append(param_input);
						}
					}
					$(SELECTOR_PRINT_FORM_CONTAINER).html(form);
					form.submit();
					return false;
				},
				initPrintButton:function() {
					$(SELECTOR_PRINT_LINK).click(
						$result.runPrintForm
					);
				}
			};
			return $result;
		}(),
		orderForm:function(){
			let $order_form;
			let SELECTOR_FORM_CONTENT = ".j-order-form-block";
			let SELECTOR_RESULT_CONTENT = ".result";
			let SELECTOR_FORM_RESULT_PLACE = ".form_result_place";
			let SELECTOR_BACK_LINK = ".back_link";
			$order_form = {
				loadOrderForm:function(form_data = null) {
					$this.result.setInputData(form_data);
					let type = form_data.user_type === 2 ? 'f': 'u';
					fw_MOJAX.send({
						to: '.j-order-form-block',
						url: `/ajax/forms/getCalculatorOrderForm${type}/`,
						user_success: function(data, user_data){
							$order_form.showOrderForm();
							$order_form.initVisual();
							$this.calculator.hideCalculator();
							$this.result.hideResult();
							$order_form.initBackLink();
							$order_form.scrollToTop();
							initAgreeWin();
							$this.result.putInputDataIntoForm(SELECTOR_FORM_CONTENT);
							$('[name=phone1]').ready(function () {
								$('[name=phone1]').inputmask('+7 (\\999) 999-99-99');
							});
						},
						overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
						overlay_to: SELECTOR_GLOBAL_WRAPPER
					});
				},
				initVisual:function() {
					$(SELECTOR_FORM_CONTENT).find('.btn_all').addClass('btn_big');
					$(SELECTOR_FORM_CONTENT).find('.btn_all').removeClass('r_float');
					$(SELECTOR_FORM_CONTENT).find(SELECTOR_FORM_RESULT_PLACE).html(
						$this.result.getResultBlockContent()
					);
				},
				showOrderForm:function() {
					$(SELECTOR_FORM_CONTENT).show();
				},
				hideOrderForm:function() {
					$('.j-order-form-block').hide();
					$('.js-back-link-button').hide();
				},
				initBackLink:function() {
					$('.js-back-link-button').click($order_form.backToCalculator);
				},
				backToCalculator:function() {
					$order_form.hideOrderForm();
					$this.calculator.showCalculator();
					$this.result.showResult();
					/*$this.calculator.hideResultContent();*/
					return false;
				},
				scrollToTop:function(){
					if (fw_MAMMON.isMobileDevice()) {
						$('body,html').scrollTo($(SELECTOR_FORM_CONTENT),500);
					}
					else {
						$('.fw_win_wrapper').scrollTo($(SELECTOR_FORM_CONTENT),500);
					}
				},
				goToNewCaclulation:function(){
					$order_form.backToCalculator();
					$this.result.clearResult();
					$(SELECTOR_RESULT_CONTENT).hide();
				}
			};
			return $order_form;
		}(),

	};
	return $this;
}();


$(function(){
	if($('#leasing_calc').length) {
		_LEASING_CALCULATOR.calculator_window.show('#leasing_calc');
	}
	$('.open_calculator_action, .right_block .calculator_submit_action_r').click(
		function() {
			_LEASING_CALCULATOR.calculator_window.open();
		}
	);
	
	
	window.onload = function() {
		_LEASING_CALCULATOR.calculator_window.initWindow();
	};
	
});


