var __COMMON = function (params = {}) {

    var elements = {
        body: $('body')
    };
    var _init = {
        commonFunctions: function () {
            functions.initFooterMenuEvents();
            functions.initHeaderMenuEvents();
            functions.initShowMore();
            functions.initBreadcrumbs();
            functions.PriceTableWrapping();
            functions.initSliders();
            functions.initTabs();
            functions.initTabsContacts();
            functions.initSpoiler();
            functions.initSideMenu();
            functions.initSmoothScroll();
            functions.updateTopSliderBackground();
        },
        rootPage: function () {
            functions.initRoot();
            functions.initRootBanner();
        },
        content: function() {
            functions.initTabsLinks();
        },
        modelPage: function () {

        },
        locations: function () {

        },
        contactsPage: function () {
            functions.initContactsPageMap();
        }
    };
    var functions;
    functions = {
        initTabs: function () {
            $(".j-tabs").each(function () {
                var active = 0;
                var activeTab = $(this).find('.tabs-header .-active');
                if (activeTab.length) {
                    active = activeTab.closest('li').index();
                }
                $(this).tabs({
                    active: active
                });
            });
        },
        initTabsContacts: function () {
            if ($('.content_tabs').length) {
                new Tabby('.content_tabs');
            }
        },
        initMap: function () {
            window.onload = function (fn) {
                typeof (fn) == 'function' && fn();
                return function () {
                    if (typeof ymaps !== 'undefined') {
                        ymaps.ready(function () {
                            initTemplates();
                            initSectionMap();
                        });
                    }
                }
            }(window.onload);

            var iconLayout;
            var icons;

            function initSectionMap() {
                var myMap = new ymaps.Map("yamap", {
                    behaviors: ['default', 'scrollZoom'],
                    center: [55.849116, 37.474759],
                    zoom: 10,
                    controls: ['zoomControl', 'trafficControl', 'rulerControl', 'geolocationControl']
                });
                MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
                    '<div class="toyota_icon" data-dealer-code="" >\
                        <div class="icon_header">$[properties.iconContent]</div>\
                    </div>'
                ),
                    myPlacemark = new ymaps.Placemark(myMap.getCenter(), {
                        hintContent: 'Тойота Центр Шереметьево',
                        balloonContent: 'Тойота Центр Шереметьево'
                    }, {
                        // Опции.
                        // Необходимо указать данный тип макета.
                        iconLayout: 'default#image',
                        // Своё изображение иконки метки.
                        iconImageHref: 'images/myIcon.gif',
                        // Размеры метки.
                        iconImageSize: [201, 27],
                        // Смещение левого верхнего угла иконки относительно
                        // её "ножки" (точки привязки).
                        iconImageOffset: [-100, -40]
                    }),
                    myPlacemarkWithContent = new ymaps.Placemark([55.908162, 37.408247], {
                        hintContent: 'Тойота Центр Шереметьево',
                        balloonContent: 'Тойота Центр Шереметьево',
                        iconContent: 'Тойота Центр Шереметьево'
                    }, {
                        // Опции.
                        // Необходимо указать данный тип макета.
                        iconLayout: 'default#imageWithContent',
                        // Своё изображение иконки метки.
                        iconImageHref: 'images/ball.png',
                        // Размеры метки.
                        iconImageSize: [170, 48],
                        // Смещение левого верхнего угла иконки относительно
                        // её "ножки" (точки привязки).
                        iconImageOffset: [-100, -40],
                        // Смещение слоя с содержимым относительно слоя с картинкой.
                        iconContentOffset: [0, 0],
                        // Макет содержимого.
                        iconContentLayout: MyIconContentLayout
                    });

                icons.add(new ymaps.Placemark([55.794922, 37.651583], {
                    hintContent: 'Тойота Центр Сокольники',
                    balloonContent: 'Тойота Центр Сокольники',
                    iconContent: 'Тойота Центр Сокольники'
                }, {
                    // Опции.
                    // Необходимо указать данный тип макета.
                    iconLayout: 'default#imageWithContent',
                    // Своё изображение иконки метки.
                    iconImageHref: 'images/ball.png',
                    // Размеры метки.
                    iconImageSize: [170, 48],
                    // Смещение левого верхнего угла иконки относительно
                    // её "ножки" (точки привязки).
                    iconImageOffset: [-100, -40],
                    // Смещение слоя с содержимым относительно слоя с картинкой.
                    iconContentOffset: [0, 0],
                    // Макет содержимого.
                    iconContentLayout: MyIconContentLayout
                }));

                /*icons.add(new ymaps.Placemark([55.908054, 37.408226],
                    {
                        territoryName: 'Тойота Центр Шереметьево',
                        dealer_code: 'sheremetevo',
                        iconWidth: 172
                    }, {
                        'iconOffset' : [-110, -40]
                    }));*/


                icons.add(new ymaps.Placemark([55.791367, 37.258041], {
                    hintContent: 'Тойота Центр Новорижский',
                    balloonContent: 'Тойота Центр Новорижский',
                    iconContent: 'Тойота Центр Новорижский'
                }, {
                    // Опции.
                    // Необходимо указать данный тип макета.
                    iconLayout: 'default#imageWithContent',
                    // Своё изображение иконки метки.
                    iconImageHref: 'images/ball.png',
                    // Размеры метки.
                    iconImageSize: [170, 48],
                    // Смещение левого верхнего угла иконки относительно
                    // её "ножки" (точки привязки).
                    iconImageOffset: [-100, -40],
                    // Смещение слоя с содержимым относительно слоя с картинкой.
                    iconContentOffset: [0, 0],
                    // Макет содержимого.
                    iconContentLayout: MyIconContentLayout
                }));
                icons.add(new ymaps.Placemark([55.768269, 37.527498], {
                    hintContent: 'Тойота Центр Сити',
                    balloonContent: 'Тойота Центр Сити',
                    iconContent: 'Тойота Центр Сити'
                }, {
                    // Опции.
                    // Необходимо указать данный тип макета.
                    iconLayout: 'default#imageWithContent',
                    // Своё изображение иконки метки.
                    iconImageHref: 'images/ball.png',
                    // Размеры метки.
                    iconImageSize: [170, 48],
                    // Смещение левого верхнего угла иконки относительно
                    // её "ножки" (точки привязки).
                    iconImageOffset: [-100, -40],
                    // Смещение слоя с содержимым относительно слоя с картинкой.
                    iconContentOffset: [0, 0],
                    // Макет содержимого.
                    iconContentLayout: MyIconContentLayout
                }));

                myMap.geoObjects
                    .add(icons)
                    .add(myPlacemark)
                    .add(myPlacemarkWithContent);
            }

            function initTemplates() {
                icons = new ymaps.GeoObjectCollection({}, {
                    balloonShadow: false,
                    interactiveZIndex: false,
                    openEmptyHint: true,
                    hasHint: false,
                    pane: 'overlaps'
                });
            }
        },
        initSpoiler: function () {
            $('.content-page .content_spoiler > *:first-child').on('click', function () {
                $(this).parent().toggleClass('-opened');
                $(this).next().slideToggle();
            });
        },
        initContactsPageMap: function () {
            var initSpb = function initSpb() {
                mapSpb = new ymaps.Map('map-spb', {
                    center: [60.007488, 30.341022],
                    zoom: 10,
                    controls: ['zoomControl'],
                    behaviors: ['default']
                }, {
                    searchControlProvider: 'yandex#search'
                });
                mapUsability(mapSpb);

                var orbPlacemark = new ymaps.Placemark([60.007488, 30.341022], {}, {
                    iconLayout: 'default#image',
                    iconImageHref: '/assets/client/images/geotag-contacts.svg',
                    iconImageSize: [44, 58],
                    balloonOffset: [0, -77],
                    iconImageOffset: [-25, -64]
                });
                mapSpb.geoObjects.add(orbPlacemark);

                var olPlacemark = new ymaps.Placemark([59.9291458,30.3831844], {}, {
                    iconLayout: 'default#image',
                    iconImageHref: '/assets/client/images/geotag-contacts.svg',
                    iconImageSize: [44, 58],
                    balloonOffset: [0, -77],
                    iconImageOffset: [-25, -64]
                });
                mapSpb.geoObjects.add(olPlacemark);

            };

            var mapSpb;
            ymaps.ready(initSpb);

            var initMoscow = function initMoscow() {
                mapMoscow = new ymaps.Map('map-moscow', {
                    center: [55.773045, 37.435259],
                    zoom: 10,
                    controls: ['zoomControl'],
                    behaviors: ['default']
                }, {
                    searchControlProvider: 'yandex#search'
                });
                mapUsability(mapMoscow);

                var cvPlacemark = new ymaps.Placemark([55.841185, 37.429590], {}, {
                    iconLayout: 'default#image',
                    iconImageHref: '/assets/client/images/geotag-contacts.svg',
                    iconImageSize: [44, 58],
                    balloonOffset: [0, -77],
                    iconImageOffset: [-25, -64]
                });
                mapMoscow.geoObjects.add(cvPlacemark);

            };

            var mapMoscow;
            ymaps.ready(initMoscow);

            //запрет прокручивания колесом\пальцем для возможности прокрутки страницы
            function mapUsability(map) {
                map.behaviors.disable('scrollZoom');
                //на мобильных устройствах... (проверяем по userAgent браузера)
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    //... отключаем перетаскивание карты
                    map.behaviors.disable('drag');
                }
            }
        },
        initShowMore: function () {
            let buttons = $('.show-more-button');
            if (buttons.length) {
                $.each(buttons, function (key) {
                    let button = buttons.eq(key),
                        hiddenBlock = button.prev(),
                        textToHide = button.attr('data-hide');
                    if (textToHide !== undefined) {
                        let isHidden = true,
                            textBlock = button.find('.text'),
                            textToShow = textBlock.text().trim();
                        button.on('click', function (e) {
                            e.preventDefault();
                            if (isHidden) {
                                textBlock.text(textToHide);
                                hiddenBlock.slideDown();
                                isHidden = false;
                            } else {
                                textBlock.text(textToShow);
                                hiddenBlock.slideUp();
                                isHidden = true;
                            }
                        });
                    } else {
                        button.on('click', function (e) {
                            e.preventDefault();
                            button.css({
                                visibility: 'hidden',
                                pointerEvents: 'none'
                            });
                            hiddenBlock.slideDown();
                        });
                    }
                });
            }
        },
        initHeaderMenuEvents: function () {
            var header = $('.main-header'),
                menuItem = header.find('.menu > li');
            menuItem.find('.menu_item.mobile').on('click', function () {
                $(this).toggleClass('-active');
                $(this).siblings('.submenu').slideToggle();
            });
            header.find('.burger_btn').on('click', function () {
                elements.body.toggleClass('-mobile-menu');
                header.toggleClass('-mobile-menu');
            });
            header.find('.contacts_btn').on('click', function () {
                elements.body.toggleClass('-mobile-contacts');
                header.toggleClass('-mobile-contacts');
            });
        },
        initFooterMenuEvents: function () {
            var menuHeader = $('.main-footer .header');
            menuHeader.on('click', function () {
                $(this).next().slideToggle();
                $(this).toggleClass('-active');
            });
        },
        initBreadcrumbs: function () {
            let items_count = $('.breadcrumbs .item').length;
            if (items_count) {
                tns({
                    container: '.tns_slider .tns_slider_wrap',
                    items: 5.5,
                    nav: false,
                    mouseDrag: true,
                    slideBy: 1,
                    swipeAngle: false,
                    autoplay: false,
                    autoWidth: true,
                    loop: false,
                    center: true,
                    startIndex: items_count,
                    controls: false,
                });
            }
        },
        initRootBanner: function () {
            $('.main-banner .slider').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow: '.main-banner .arrow.-prev',
                nextArrow: '.main-banner .arrow.-next'
            });
        },
        initRoot: function(){
            $('.j-scrollToCalc').click(function(){
                $('html, body').animate({scrollTop: $('#filterOfCars').offset().top}, 1000);
            });
        },
        PriceTableWrapping: function () {
            $('.price_table').each(function () {
                $(this).wrap("<div class='price_table_wrapper'></div>");
            });
        },
        initSliders: function () {
            $('.j-slider-news').slick({
                variableWidth: true,
                infinite: false,
                arrows: false,
                autoplay: false,
                dots: false,
                swipeToSlide: true,
            });
            $('.j-top-slider').slick({
                infinite: true,
                arrows: true,
                dots: false,
                autoplay: 5000,
                prevArrow: '.j-top-slider-wrapper .arrow.-prev',
                nextArrow: '.j-top-slider-wrapper .arrow.-next',
            });
            $('.j-pluses').slick({
                slidesToShow: 1,
                infinite: false,
                arrows: true,
                dots: false,
                prevArrow: '.j-pluses-wrapper .arrow.-prev',
                nextArrow: '.j-pluses-wrapper .arrow.-next',
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                ],
            });
            $('.j-slider-clients').slick({
                arrows: true,
                dots: false,
                infinite: true,
                variableWidth: true,
                prevArrow: '.j-clients-wrapper .arrow.-prev',
                nextArrow: '.j-clients-wrapper .arrow.-next',
            });
        },
        updateTopSliderBackground: function() {
            function update() {
                const slides = document.querySelectorAll('.top-slide');

                if (slides) {
                    slides.forEach(slide => {
                        const desktopImage = slide.getAttribute('data-desktop-image');
                        const phoneImage = slide.getAttribute('data-phone-image');

                        if (window.innerWidth < 576) {
                            slide.style.backgroundImage = `url(${phoneImage})`;
                        } else {
                            slide.style.backgroundImage = `url(${desktopImage})`;
                        }
                    });
                }
            }
            update();
            window.addEventListener('resize', update);
        },
        initTabsLinks: function () {
            var items_count = $('.tabs-links .item').length;
            if (items_count) {
                tns({
                    container: '.tns_slider',
                    nav: false,
                    mouseDrag: true,
                    slideBy: 1,
                    swipeAngle: false,
                    autoplay: false,
                    autoWidth: true,
                    loop: false,
                    controls: false,
                });
            }

        },
        initSideMenu: function () {
            $('.side-menu .item').each(function () {
                $(this).hover(function (e) {
                    $('.side-menu .item').removeClass('-active');
                    $(this).addClass('-active');
                }, function (e) {
                    $(this).removeClass('-active');
                });
            });

        },
        initSmoothScroll: function() {
            var header = $('.main-header');
            var body = $('body');
            $('a.smooth-scroll').click(function(event) {
                if (header.hasClass('-mobile-menu')) {
                    header.removeClass('-mobile-menu');
                    body.removeClass('-mobile-menu');
                }
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: $($.attr(this, 'href')).offset().top
                }, 800);
            });
        }
    };
    return _init;
}();
