let __CARS_LIST = function (params) {
    let nodes = {
        carsList: $('.cars-list-wrap')
    };
    let sort = 0,
        inputs;
    let filter = {
        initialized: false,
        filter: document.getElementById('filter-open')
    };

    let __init = function() {
        /*functions.loadList();*/

        /*functions.initSort();*/
        /*functions.initListLinks();*/
        /*functions.getFilter(nodes.filter);*/
    };
    let functions = {
        /*loadList: function(page = 1) {
            fw_MOJAX.send({
                url: '/ajax/' + params.module + '/getCarsList/',
                data: {
                    model: params.model,
                    modification: params.modification,
                    page: page,
                    sort: sort,
                    filter: filter.values
                },
                to: nodes.carsList,
                insert_mode: page > 1 ? fw_MOJAX.INSERT_MODE_APPEND : fw_MOJAX.INSERT_MODE_HTML,
                overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
                overlay_to: nodes.carsList,
                user_success: $.proxy(function (json_data) {
                    functions.initShowMoreButton();
                    functions.initListPhotos();
                })
            });
        },
        getFilter: function(to = null) {
            console.log(to);
            fw_MOJAX.send({
                url: '/ajax/carsUsed/getFilter/',
                data: {
                    model: params.model,
                    modification: params.modification,
                    get_template: !!to,
                    filter: filter.values
                },
                to: to,
                overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
                overlay_to: to,
                user_success: $.proxy(function (json_data) {
                    functions.showFilteredCount(json_data.cars_count);
                    if (to) {
                        filter.initialized = true;
                        filter.values = {};
                        functions.initRangeSlider(+json_data.filter.min_price, +json_data.filter.max_price);
                        functions.initFilterEvents();
                        functions.initClearFilter();
                    }
                })
            });
        },
        initClearFilter: function() {
            $('.clear-cars_filter').on('click', function () {
                filter.values = {};
                let text_inputs =  $(filter.template).find('input[type=text]');
                let checkbox_inputs =  $(filter.template).find('input[type=checkbox]');

                $(inputs).each(function () {
                    $(text_inputs).val(null);
                    $(checkbox_inputs).removeAttr("checked").parent().removeClass('-active');
                });
                /!*functions.getFilter(nodes.filter);*!/
                functions.loadList();
            });
        },
        initPaginator() {
            $('.btn_list_changer:not(.active) .btn_list_changer_link').on('click', function(e) {
                e.preventDefault();
                let page = $(this).attr('data-page');
                console.log(page);
                functions.loadList(page);
            })
        },
        initShowMoreButton: function() {
            nodes.carsList.find('.show-more').on('click', function() {
                functions.loadList($(this).attr('data-page'));
                $(this).remove();
            })
        },
        initFilter: function() {
            filter.template = document.createElement('div');

            $('.cars-list-controls .filter').on('click', function() {
                filter.window = fw_WIN.create({
                    width: 754,
                    without_padding: true,
                    title: false,
                    content: filter.template,
                    container_class: "filter_window",
                    use_scroll_fix: true,
                    block_mode: true,
                    top_offset: 20
                });
                if (!filter.initialized) {
                    functions.getFilterValues(filter.template);
                } else {
                    functions.initFilterEvents();
                }
            });
        },
        getFilterValues: function(to = null) {
            fw_MOJAX.send({
                url: '/ajax/cars/getFilter/',
                data: {
                    model: params.model,
                    modification: params.modification,
                    get_template: !!to,
                    filter: filter.values
                },
                to: to,
                overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
                overlay_to: to,
                user_success: $.proxy(function (json_data) {
                    functions.showFilteredCount(json_data.cars_count);
                    if (to) {
                        filter.initialized = true;
                        filter.values = {};
                        functions.initRangeSlider(+json_data.filter.min_price, +json_data.filter.max_price);
                        functions.initFilterEvents();
                    }
                })
            });
        },
        showFilteredCount: function(count) {
            let declension;
            let remainder = count % 100;
            if (remainder >= 10 && remainder <= 20) {
                declension = 'й';
            } else {
                remainder = count % 10;
                if (remainder === 1) {
                    declension = 'e';
                } else if (remainder === 2 || remainder === 3 || remainder === 4) {
                    declension = 'я';
                } else {
                    declension = 'й';
                }
            }
            $(filter.template).find('input[type=submit]').val('Показать ' + count + ' предложени' + declension)
        },
        initFilterEvents: function() {
            let inputs = $(filter.template).find('input[type=radio], input[type=checkbox]'),
                form = $(filter.template).find('form');
            inputs.on('change', function(){
                let type = $(this).attr('type'),
                    key = $(this).attr('name'),
                    val = $(this).val();

                if (type === 'checkbox') {
                    if (this.checked) {
                        filter.values[key] = [];
                        $.each(inputs.filter('[name="' + key + '"]:checked'), function(i, el){
                            filter.values[key].push($(el).val());
                        })
                    } else {
                        filter.values[key].splice(filter.values[key].indexOf(val), 1);
                    }
                } else {
                    if (val === "0") {
                        filter.values[key] = [];
                    } else {
                        filter.values[key] = [val];
                    }
                }
                functions.getFilterValues();
            });
            form.on('submit', function(e){
                e.preventDefault();
                functions.loadList();
                let filters_count = 0;
                for (const key in filter.values) {
                    if (filter.values[key].length > 0) {
                        filters_count++;
                    }
                }
                if (filters_count) {
                    $('.cars-list-controls .filter .count').html(filters_count);
                } else {
                    $('.cars-list-controls .filter .count').empty();
                }
                filter.window.close();
            });
        },
        initRangeSlider: function(min, max) {
            let rangeSlider = filter.template.querySelector('#price-slider');
            noUiSlider.create(rangeSlider, {
                start: [min, max],
                connect: true,
                range: {
                    'min': min,
                    'max': max
                }
            });

            let rangeInputs = [
                filter.template.querySelector('#price-min'),
                filter.template.querySelector('#price-max')
            ];
            rangeInputs[0].addEventListener('change', function () {
                rangeSlider.noUiSlider.set([this.value, null]);
                filter.values.min_price = this.value;
                functions.getFilterValues();
            });
            rangeInputs[1].addEventListener('change', function () {
                rangeSlider.noUiSlider.set([null, this.value]);
                filter.values.max_price = this.value;
                functions.getFilterValues();
            });
            rangeSlider.noUiSlider.on('update', function (values, handle) {
                rangeInputs[handle].value = parseInt(values[handle]);
            });
            rangeSlider.noUiSlider.on('set', function (values, handle) {
                if (handle === 0) {
                    console.log(+values[0]);
                    console.log(min);
                    if (+values[0] === min) {
                        delete filter.values.min_price;
                    } else {
                        filter.values.min_price = values[0];
                    }
                } else {
                    if (+values[1] === max) {
                        delete filter.values.max_price;
                    } else {
                        filter.values.max_price = values[1];
                    }
                }
                functions.getFilterValues();
            });
        },
        initSort: function() {
            let sorter = $('.cars-list-controls .sort');
            sorter.on('click', function(e) {
                sorter.toggleClass('-active');
                e.stopPropagation();
                $(document).one('click', function(e) {
                    sorter.removeClass('-active');
                    e.stopPropagation();
                })
            }).find('.sort_item').on('click', function() {
                let value = +$(this).attr('data-value');
                if (sort !== value) {
                    sort = value;
                    functions.loadList();
                    $(this).addClass('-selected').siblings().removeClass('-selected');
                }
            });
        },
        initListLinks: function() {
            $('.cars-list-links .more').on('click', function(e) {
                e.preventDefault();
                $(this).parent().addClass('-expanded');
                $(this).remove();
            })
        },
        initListPhotos: function() {
            let hover = nodes.carsList.find('.photo .hover');
            hover.find('> div').on('mouseenter', function() {
                let src = params.prefix + $(this).attr('data-src');
                $(this).parents('.photo').find('img').attr('src', src);
            });

            // убрал mouseleave что бы нормально отображались картинки в слайдере в списке бу авто. С ним картинки появлялись только при наведении на слайдер и сразу пропадали

            /!*hover.on('mouseleave', function() {
                let image = $(this).siblings('img'),
                    src = params.prefix + image.attr('data-src');
                image.attr('src', src);
            });*!/
        }*/
    };

    return __init();
};
