let __CARS_CARD = function (params) {
    let __init = function() {
        functions.initCarOptions();
        functions.initCarContacts();
        functions.initOffersSelect();
    };
    let functions = {
        initCarOptions: function () {
            let tabsSelector = '.car-options .tabs';
            if ($(tabsSelector).length) {
                new Tabby(tabsSelector);
            }

            let optionsBlock = $('.car-options');
            optionsBlock.find('.spoiler > *:first-child').on('click', function(){
                $(this).parent().toggleClass('-opened');
                $(this).next().slideToggle();
            });

            optionsBlock.find('.more_button').on('click', function(){
                fWin = fw_WIN.create({
                    lock_page: false,
                    top_offset: 50,
                    offset: 50,
                    content: '',
                    title: "Описание опции",
                    container_class: "options-window",
                    content_class: "",
                    width: 500,
                    without_padding: true,
                    use_scroll_fix: true
                });
                let description = $(this).attr('data-description');
                let oid = $(this).attr('data-oid');
                var to = $(fWin.content);

                if (oid) {
                    fw_MOJAX.send({
                        url: '/ajax/cars/getOptionPhoto/',
                        data: {
                            oid: oid
                        },
                        overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
                        overlay_to: to,
                        user_success: $.proxy(function (json_data) {
                            if (description) {
                                to.append("<p>" + description + "</p>")
                            }
                            to.append('<img src="data:image/jpeg;base64,' + json_data.photo + '">')
                        })
                    });
                } else {
                    to.append("<p>" + description + "</p>");
                }
            });
        },
        initCarContacts: function () {
            let moreButton = $('.car-contacts .more');
            let hiddenBlock = moreButton.prev();
            moreButton.on('click', function() {
                moreButton.toggleClass('-opened');
                hiddenBlock.slideToggle();
            });
        },
        initOffersSelect: function() {
            $('#car-offers').on('change', function() {
                $(this).prop('disabled', 'disabled');
                location.href = $(this).val();
                this.selectedIndex = 0;
            });
        }
    };

    return __init();
};
