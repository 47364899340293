if(typeof(JQUERY_EXT_INCLUDED) === "undefined"){
	var JQUERY_EXT_INCLUDED = true;
	(function($) {
		"use strict";
		$.customTabsEvents = {
			onOpen: null,
			onClose: null,
			onDisable: null,
			onEnable: null
		};
		$.fn.setCustomTabs = function(callbacks){
			callbacks = callbacks || {};
			var $tabs = {};
			var $this = this;
			$tabs = {
				default_tab: null,
				current_tab: null,
				container: $($this),
				tabs_list: {}
			};
			$tabs.tabs_list = function(){
				var $tabs_list = {};
				$($this).find(".additional_tab_navigation .tab_link").each(function(){
					var code = this.hash.slice(1);
					var new_callbacks = $.extend({}, $.customTabsEvents, callbacks['all'], callbacks[code]);
					var $tab = {
						callbacks: new_callbacks,
						header_element: $(this),
						content_elements: $tabs.container.find("."+code),
						open: function(){
							var last_tab = $tabs.current_tab; 
							if(last_tab){
								last_tab.close();
							}
							$tab.header_element.addClass('active');
							$tab.content_elements.show();
							$tabs.current_tab = this;
							if($tab.callbacks.onOpen) $tab.callbacks.onOpen($tab, last_tab);
						},
						close: function(){
							$tab.header_element.removeClass('active');
							$tab.content_elements.hide();
							if($tab.callbacks.onClose) $tab.callbacks.onClose($tab);
						},
						disable: function(){
							if($tab.callbacks.onDisable) $tab.callbacks.onDisable($tab);
						},
						enable: function(){
							if($tab.callbacks.onEnable) $tab.callbacks.onEnable($tab);
						}
					};
					$tab.header_element.bind("click.cTabs", function(e){
						$tab.open();
						fw_MAMMON.preventDefaultSafe(e);
					});
					$tabs_list[code] = $tab;
					if($tab.header_element.hasClass("default")){
						$tabs.default_tab = $tab;
					}
				});
				return $tabs_list;
			}();
			if($tabs.default_tab){
				$tabs.default_tab.open();
			}
			return $tabs;
		};
		$.fn.smartFocus = function(){
			if($(this).emptySet()){
				console.warn("Не удалось найти jQuery объект, на который вешаем главное событие smartFocus.");
				return this;
			}
			$(this).each(function(){
				var inputs = $(this).find("textarea,input");
				inputs.bind("focus.smartFocus",function(container){
					return function(){
						$(container).addClass("focus");
					}
				}(this)).bind("blur.smartFocus",function(container){
					return function(){
						$(container).removeClass("focus");
					}
				}(this));
			});
			return this;
		};
		$.fn.smartUnHover = function(){
			$(this).unbind(".smartHover");
			var data = ($(this).data('smartHover_active') === undefined)?[]:$(this).data('smartHover_active');
			for(var i = 0; i <= data.length; i++){
				$(this).off(".smartHover",data[i]);
			}
			$(this).data('smartHover_active',[]);
			return this;
		};
		$.fn.smartHover = function(hovered_params,hovered_params_class){
			if($(this).emptySet()){
				console.warn("Не удалось найти jQuery объект, на который вешаем главное событие smartHover.");
				return this;
			}
			if(hovered_params_class !== undefined){
				// ставим обработку для ON
				$(this).on("mouseenter.smartHover",hovered_params,function(){
						$(this).addClass(hovered_params_class);
					}
				).on("mouseleave.smartHover",hovered_params,function(){
						$(this).removeClass(hovered_params_class);
					}
				);
				var data = ($(this).data('smartHover_active') === undefined)?[]:$(this).data('smartHover_active');
				data[data.length] = hovered_params;
				$(this).data('smartHover_active', data);
			}
			else{
				$(this).bind("mouseenter.smartHover",function(){
						$(this).addClass(hovered_params);
					}
				).bind("mouseleave.smartHover",function(){
						$(this).removeClass(hovered_params);
					}
				);
			}
			return this;
		};
		$.fn.emptySet = function(){
			return $(this).length === 0;
		};
		$.fn.setAutoFixed = function(custom_options){
			// ПОка неизвестно поведение на мобильных устройствах.
			if(fw_MAMMON.lteIE7()) return;
			var event_tag = "set_auto_fixed";
			var now_fixed = false;
			var now_limited = false;
			var doc = $(document);
			var win = $(window);
			var container = $("<div>");
			var target = $(this);
			var default_options = {
				fixed_class_name: "fixed",
				full_screen: false,
				bottom_limit_obj: null,
				min_height: 0,
				start_offset: 0,
				bottom_limit_offset: 0
			};
			var options = $.extend({}, default_options, custom_options);
			
			prepareFakeContainer();
			setCheckTopEvent();
			checkTopOffset();
			
			if(!$.empty(options.bottom_limit_obj)){
				setCheckLimitEvent();
				checkLimitOffset();
			}
			
			function prepareFakeContainer(){
				target.before(container);
				container.html(target.detach());
			}
			function setCheckTopEvent(){
				win.bind("load."+event_tag+", scroll."+event_tag+", resize."+event_tag, function(){
					checkTopOffset();
				});
			}
			function setCheckLimitEvent(){
				win.bind("load."+event_tag+", scroll."+event_tag+", resize."+event_tag, function(){
					checkLimitOffset();
				});
			}
			function setWidthFromContainer(){
				target.css({width: container.width()});
			}
			function setLeftOffsets(){
				target.css({left: getDynLeft()});
			}
			function getDynLeft(){
				var doc_offset = doc.scrollLeft();
				var left_offset = container.offset().left;
				return left_offset - doc_offset;
			}
			function checkLimitOffset(){
				var doc_offset = doc.scrollTop();
				// Проверяем на ограничивающий снизу элемент
				var limit_offset = $(options.bottom_limit_obj).offset().top - options.bottom_limit_offset - target.outerHeight() - options.start_offset;
				var must_be_limited = doc_offset >= limit_offset;
				if(now_fixed && must_be_limited){
					if(!now_limited){
						now_limited = true;
					}
					var limit_delta = doc_offset - limit_offset;
					target.css({top: -limit_delta + options.start_offset});
				}
				else if(now_fixed && now_limited){
					now_limited = false;
					target.css({top: options.start_offset});
				}
			}
			// Функция запускается при каждом эвенте ресайза или скролла.
			function checkTopOffset(){
				if(!target.is(":visible")) return;
				var top_offset = container.offset().top - options.start_offset;
				var doc_offset = doc.scrollTop();
				var must_be_fixed = doc_offset > top_offset;
				var min_height_wrong = options.min_height > 0 ? win.height() < options.min_height : false;
				if(!min_height_wrong && must_be_fixed){
					// Всё внутри выполняется всегда когда блок фиксед при ресайзе или скролле
					if(!now_fixed){
						// Переходим в режим FIXED
						container.css({height: container.height()}).addClass(options.fixed_class_name);
						var target_css = {left: 0, top: options.start_offset, position: "fixed"};
						if(options.full_screen){
							target_css.width = "100%";
						}
						target.css(target_css);
						now_fixed = true;
					}
					// Правим отступ слева
					if(!options.full_screen){
						setLeftOffsets();
						setWidthFromContainer();
					}
				}
				else if(now_fixed && (!must_be_fixed || min_height_wrong)){
					container.css({height: ""}).removeClass(options.fixed_class_name);
					target.css({left: "", top: "", position: "", width: ""});
					now_fixed = false;
				}
			}
			function recalculate(){
				if(!options.full_screen && now_fixed){
					setLeftOffsets();
					setWidthFromContainer();
				}
			}
			var fixed_interface = {
				recalc: recalculate
			};
			return fixed_interface;
		};
		$.fn.slideRight = function(delay, func){
			var default_delay = 300;
			if(typeof($(this).data('width')) === 'undefined'){
				$(this).data('width', $(this).width());
				$(this).css({'width': 0});
			}
			function callback(){
				if(typeof(func) === 'function') func.call(this);
			}
			$(this).show().stop().animate({'width': $(this).data('width')}, delay || default_delay, callback);
			return this;
		};
		$.fn.slideLeft = function(delay, func){
			var default_delay = 300;
			if(typeof($(this).data('width')) === 'undefined')
				$(this).data('width', $(this).width());
			function callback(){
				$(this).hide();
				if(typeof(func) === 'function') func.call(this);
			}
			$(this).stop().animate({'width': 0}, delay || default_delay, callback);
			return this;
		};
		$.empty = function(mixed_var){
			if(typeof(mixed_var) === "function") return false;
			if(typeof(mixed_var) === "boolean") return !mixed_var;
			if(!mixed_var || 0 == mixed_var.length || mixed_var === "0" || (mixed_var instanceof Date && isNaN(Number(mixed_var)))) return true;
			if (typeof(mixed_var) == 'string' || typeof(mixed_var) == 'number') return false;
			return $.isEmptyObject(mixed_var);
		};
		$.nl2br = function(str, is_xhtml) {
			// http://kevin.vanzonneveld.net
			// +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
			// +   improved by: Philip Peterson
			// +   improved by: Onno Marsman
			// +   improved by: Atli Þór
			// +   bugfixed by: Onno Marsman
			// +      input by: Brett Zamir (http://brett-zamir.me)
			// +   bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
			// +   improved by: Brett Zamir (http://brett-zamir.me)
			// +   improved by: Maximusya
			// *     example 1: nl2br('Kevin\nvan\nZonneveld');
			// *     returns 1: 'Kevin<br />\nvan<br />\nZonneveld'
			// *     example 2: nl2br("\nOne\nTwo\n\nThree\n", false);
			// *     returns 2: '<br>\nOne<br>\nTwo<br>\n<br>\nThree<br>\n'
			// *     example 3: nl2br("\nOne\nTwo\n\nThree\n", true);
			// *     returns 3: '<br />\nOne<br />\nTwo<br />\n<br />\nThree<br />\n'
			var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display
			return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
		};
		$.fn.chunk = function(chunk_size){
			var $this = $(this);
			var chunks = [];
			for(var i=0; i < $this.length; i += chunk_size){
				chunks.push($this.slice(i, i+chunk_size));
			}
			return chunks;
		};

		$.fn.fixHeight = function(item_selector, count_in_row, use_outer_height){
			var max_height = 0;
			var blocks_in_row = [];
			var blocks = $(this).find(item_selector).chunk(count_in_row);
			for(var i=0; i < blocks.length; i++){
				max_height = 0;
				blocks[i].each(function(){
					var height = use_outer_height ? $(this).outerHeight() : $(this).height();
					if(height > max_height){
						max_height = height;
					}
				});
				blocks[i].css({height:max_height});
			}
			return this;
		};

		$.intersection = function(first_array, second_array){
			if(typeof(first_array) != 'object' || typeof(second_array) != 'object') return false;
			var intersectArray = [];
			var firstArray = [];
			var secondArray = [];
			if(first_array.length <= second_array.length){
				firstArray = first_array;
				secondArray = second_array;
			}
			else{
				firstArray = second_array;
				secondArray = first_array;
			}
			for(var i = 0; i < firstArray.length; i++)
				if($.inArray(firstArray[i], secondArray) != -1)
					intersectArray.push(firstArray[i]);
			return intersectArray;
		};
		$.difference = function(first_array, second_array){
			if(typeof(first_array) != 'object' || typeof(second_array) != 'object') return false;
			var differenceArray = [];
			for(var i = 0; i < first_array.length; i++)
				if($.inArray(first_array[i], second_array) == -1)
					differenceArray.push(first_array[i]);
			return differenceArray;
		};

		$.lengthObj = function(obj){
			var length = 0;
			for(var key in obj)
				if(obj.hasOwnProperty(key))
					length++;
			return length;
		};

		$.fn.setCursorPosition = function(pos){
			if($(this).get(0).setSelectionRange){
				$(this).get(0).setSelectionRange(pos, pos);
			}
			else if($(this).get(0).createTextRange){
				var range = $(this).get(0).createTextRange();
				range.collapse(true);
				range.moveEnd('character', pos);
				range.moveStart('character', pos);
				range.select();
			}
		};
		$.truncate = function(text, max_length){
			return $.trim(text).substring(0, max_length) + "...";
		};

		$.fn.preloadArr = function(preload_arr, event_data, fn) {
			var obj = $(this);
			obj.addClass('preload-gif');
			if (preload_arr.length == 0) {
				obj.trigger('preload.ready');
				return;
			}
			var k = 0;
			preload_arr.each(function() {
				var data = $(this).data();
				if (data && data['src']) {
					var parent = $(this).parent();
					$('<img style="display:none;">').attr({'src': data['src'], 'alt' : (data['alt'] ? data['alt'] : '')}).load(function(){
						k++;
						$(this).show();
						parent.html($(this)).addClass('ready');
						if (k == preload_arr.length) {
							typeof(fn) == 'function' && fn.call(obj, event_data);
						}
					});
				}
			});
		};

		$.fn.showBlock = function(){
			return $(this).css('display', 'block');
		};

		$.fn.showInlineBlock = function(){
			return $(this).css('display', 'inline-block');
		};

		$.fn.shuffle = function() {

			var allElems = this.get(),
				getRandom = function(max) {
					return Math.floor(Math.random() * max);
				},
				shuffled = $.map(allElems, function(){
					var random = getRandom(allElems.length),
						randEl = $(allElems[random]).clone(true)[0];
					allElems.splice(random, 1);
					return randEl;
				});

			this.each(function(i){
				$(this).replaceWith($(shuffled[i]));
			});

			return $(shuffled);
		};
	})(jQuery);

//спойлер
var SPOILER = function() {
	//PRIVATE
	var default_params = {
		state_text: "Свернуть",
		default_text: "Развернуть",
		duration: 400,
		easing: 'swing',
		state_class: "opened",
		closed: 1,
		user_callback: ""
	};
	
	//STATIC FUNCTIONS
	function createInstance(user_params) {
		var $instance = {};
		var height = 0;
		var params = $.extend(default_params, user_params);
		var button = $(params.button);
		var spoiler_target = $(this);
		var text_container = $(!$.empty(params.text_container) ? params.text_container : button);
		
		function toggleSpoilerState() {
			$instance.closed = params.closed = !params.closed;
			spoiler_target.toggleClass(params.state_class);
			text_container.html(spoiler_target.hasClass(params.state_class) ? params.state_text : params.default_text);
		}
		
		function finishCallback() {
			var fn = params.user_callback;
			$.isFunction(fn) && fn($instance);
		}
		
		function animateSpoiler() {
			height = spoiler_target.prop('scrollHeight');
			spoiler_target.stop();
			spoiler_target.animate({height: (params.closed ? height : 0)}, params.duration, params.easing, finishCallback);
			toggleSpoilerState();
			return true;
		}
		
		if ($.empty(button) || $.empty(spoiler_target)) return false;
		
		text_container.html(params.default_text);
		spoiler_target.css({height : (params.closed ? 0 : "auto"), overflow: "hidden"});
		if(spoiler_target.is(":hidden")) {
			spoiler_target.show();
		}
		
		button.click(animateSpoiler);
		
		$instance = {
			target: spoiler_target,
			button: button,
			text_container: text_container,
			user_params: user_params,
			closed: params.closed
		};
		
		return $instance;
	}
	
	$.fn.spoiler = createInstance;
}();

	// Обработчик уникальный идентификаторов для jQuery объектов.
	(function($){
		var _elements = [];
		var _i = 1;
		var _key = "MAJOR_UNQ";
		function _getUnique(jq_elem){
			var current_key = jq_elem.data(_key);
			if(!current_key){
				current_key = _i++;
				_elements[current_key] = jq_elem.data(_key, current_key);
			}
			return current_key;
		}

		$.fn.getUniqIdArr = function(){
			var ret = [];
			this.each(function(){
				ret.push(_getUnique($(this)));
			});
			return ret;
		};

		$.fn.getUniqId = function(){
			if(this.length > 1) throw "getUniqId : В коллекции не один элемент";
			return _getUnique(this)
		};

		$.getAllUniq = function(){
			return _elements;
		};

		$.getByUniq = function(uniq_key){
			if(_elements[uniq_key] == undefined) throw "Не найден уникальный ключ '"+uniq_key+"'";
			return _elements[uniq_key];
		};
	}(jQuery));
	
	// Плагин для приведения номера телефона к одному формату
	(function($){
		var PLUGIN_IDENTIFIER = "data-modifier-plugin-phone-enabled";
		var elements_validation_statuses = {};
		$.fn.isValidPhone = function(){
			return elements_validation_statuses[$(this).getUniqId()];
		};
		$.fn.usePhoneMask = function(options){
			// Доп параметры
			var $this = $(this);
			var PHONE_PLUGIN_CODE = "phone_mask";
			var current_value = "";
			var default_options = {
				onStart:function(obj){},
				onPreProcess:function(obj){},
				onSuccess:function(obj){},
				onFail:function(obj,message){
					console.error(message);
				}
			};
			// Доп функции
			var setPlugin = function(){
				if($this.is("input")){
					setEvents();
					$.extend(default_options,options);
					$this.attr(PLUGIN_IDENTIFIER,"");
				}
				return $this;
			};
			var startEdit = function(){
				default_options.onStart($this);
				preparePhoneValue();
				$this.attr(PLUGIN_IDENTIFIER,true);
			};
			var endEdit = function(){
				processPhoneValue();
				$this.attr(PLUGIN_IDENTIFIER,false);
			};
			var setEvents = function(){
				var getPasteEvent = function() {
					var el = document.createElement('input');
					var name = 'onpaste';
					el.setAttribute(name, '');
					var event_name = (typeof el[name] === 'function')?'paste':'input';
					$(el).remove();
					return event_name;
				};
				$this.bind("focus."+PHONE_PLUGIN_CODE, startEdit);
				$this.bind("blur."+PHONE_PLUGIN_CODE, endEdit);
				$this.bind("drop."+PHONE_PLUGIN_CODE, endEdit);
				$this.bind(
					getPasteEvent()+"."+PHONE_PLUGIN_CODE+" "+
					"drop."+PHONE_PLUGIN_CODE, 
					function(){
						setTimeout(endEdit, 0);
					}
				);
			};
			var preparePhoneValue = function(){
				if(!$.empty($this.val())){
					$this.val($this.val().replace(/[\D]*/gi,""));
				}
			};
			var processPhoneValue = function(){
				default_options.onPreProcess($this);
				var temp_value = $this.val();
				var getPhoneStr = function() {
					// Получаем чистые значения
					var operator_code = temp_value.substr(0,3);
					var phone_part1   = temp_value.substr(3,3);
					var phone_part2   = temp_value.substr(6,2);
					var phone_part3   = temp_value.substr(8,2);
					var trash         = temp_value.substr(10);
					// Дополняем их символами для визуализации
					if(operator_code.length != 0){
						operator_code = "("+operator_code+")";
					}
					if(phone_part1.length != 0){
						phone_part1 = " "+phone_part1;
					}
					if(phone_part2.length != 0){
						phone_part2 = "-"+phone_part2;
					}
					if(phone_part3.length != 0){
						phone_part3 = "-"+phone_part3;
					}
					if(trash.length != 0){
						trash = "."+trash;
					}
					return operator_code+phone_part1+phone_part2+phone_part3+trash;
				};
				if(!$.empty(temp_value)){
					default_options.onStart($this);
					var without_errors = true;
					// #1 Убираем все символы, не являющиеся цифрами
					temp_value = temp_value.replace(/[\D]*/gi,"");
					// #2 Убираем вначале 7 или 8 (если кто-то копировал телефон и в копированном было +7.... или 8....)
					temp_value = temp_value.replace(/^[78]/gi,"");
					// #3 Проверяем длину номера - должна быть 10 символов (если не соответствует, 
					// регистрируем ошибку - неверная длина номера телефона)
					if(!/^.{10}$/.test(temp_value)){
						without_errors = false;
						default_options.onFail($this,"Неверная длина номера телефона");
					}
					// #4 Проверяем первый символ (должна быть цифра 9, т.к. мы работаем с телефонами только 
					// мобильных операторов России) (если не 9, регистрируем ошибку - код оператора должен начинаться с 9)
					if(!/^9/.test(temp_value)){
						without_errors = false;
						default_options.onFail($this,"Код оператора должен начинаться с '9'");
					}
					// #5 Приводим результирующую строку к виду маски (000) 000-00-00.{МУСОР}
					current_value = getPhoneStr();
					if(!$.empty(current_value)){
						$this.val(current_value);
					}
					elements_validation_statuses[$this.getUniqId()] = without_errors;
					if(without_errors){
						default_options.onSuccess($this);
					}
				}
			};
			return setPlugin();
		};
	}(jQuery));

	// Плагин для приведения цены к одному формату
	(function($){
		var PLUGIN_IDENTIFIER = "data-modifier-plugin-price-enabled";
		var elements_validation_statuses = {};
		var clearedValue = function(val){
			return val.replace(/[\D]*/gi,"");
		};
		$.fn.isValidPrice = function(){
			return elements_validation_statuses[$(this).getUniqId()];
		};
		$.fn.processPriceMask = function(){
			
		};
		$.fn.usePriceMask = function(options){
			// Доп параметры
			var $this = $(this);
			var PRICE_PLUGIN_CODE = "price_mask";
			var current_value = "";
			var default_options = {
				onStart:function(obj){},
				onPreProcess:function(obj){},
				onSuccess:function(obj){},
				onFail:function(obj,message){
					console.error(message);
				}
			};
			var public_interface = {
				set: function(value){
					$this.val(value);
					processPriceValue();
					return true;
				},
				get: function(){
					return clearedValue($this.val());
				}
			};
			// Доп функции
			var setPlugin = function(){
				if($this.is("input")){
					if(!$this.attr(PLUGIN_IDENTIFIER)){
						setEvents();
						$.extend(default_options,options);
						$this.attr(PLUGIN_IDENTIFIER,"");
						processPriceValue();
					}
				}
				return public_interface;
			};
			var startEdit = function(){
				default_options.onStart($this);
				preparePriceValue();
				$this.attr(PLUGIN_IDENTIFIER,true);
			};
			var endEdit = function(){
				processPriceValue();
				$this.attr(PLUGIN_IDENTIFIER,false);
			};
			var setEvents = function(){
				var getPasteEvent = function() {
					var el = document.createElement('input');
					var name = 'onpaste';
					el.setAttribute(name, '');
					var event_name = (typeof el[name] === 'function')?'paste':'input';
					$(el).remove();
					return event_name;
				};
				$this.bind("focus."+PRICE_PLUGIN_CODE, startEdit);
				$this.bind("blur."+PRICE_PLUGIN_CODE, endEdit);
				$this.bind("process_price."+PRICE_PLUGIN_CODE, processPriceValue);
				$this.bind("drop."+PRICE_PLUGIN_CODE, endEdit);
				$this.bind(
					getPasteEvent()+"."+PRICE_PLUGIN_CODE+" "+
						"drop."+PRICE_PLUGIN_CODE,
					function(){
						setTimeout(endEdit, 0);
					}
				);
			};
			var preparePriceValue = function(){
				if(!$.empty($this.val())){
					$this.val(clearedValue($this.val()));
				}
			};
			var processPriceValue = function(){
				default_options.onPreProcess($this);
				var temp_value = $this.val();
				if(!$.empty(temp_value)){
					default_options.onStart($this);
					var without_errors = true;
					// #1 Убираем все символы, не являющиеся цифрами
					temp_value = temp_value.replace(/[\D]*/gi,"");
					// #2 Убираем вначале нули
					temp_value = temp_value.replace(/^[0]+/gi,"");
					// #5 Приводим результирующую строку к виду маски 000 000 000
					current_value = fw_MAMMON.formattedNumber(temp_value);
					if(!$.empty(current_value)){
						$this.val(current_value);
					}
					elements_validation_statuses[$this.getUniqId()] = without_errors;
					if(without_errors){
						default_options.onSuccess($this);
					}
				}
			};
			return setPlugin();
		};
	}(jQuery));
}