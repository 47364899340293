var WIN_CONTENT_CLASS = 'win_text_content';
var WIN_CONTAINER_CLASS = 'mjr-form-container';

var fw_Win_active;

var _FORMS = function () {
    var _FORMS = {
        controllers: {
            //1 Тест-драйв
            TEST_DRIVE_CONTROLLER: '/ajax/forms/getTestDriveForm/',
            //2 Расчет кредита
            CREDIT_CALC_CONTROLLER: '/ajax/forms/getCreditCalcForm/',
            MAKE_REQUEST_FORM_CONTROLLER: '/ajax/forms/getMakeRequestForm/',
            //3 Обратная связь
            FEEDBACK_CONTROLLER: '/ajax/forms/getFeedBackForm/',
            GET_FORM_CONTROLLER: '/ajax/forms/getForm/',
            //4 Индивидуальное предложение
            INDIVIDUAL_CONTROLLER: '/ajax/forms/getIndividualForm/',
            //5 Узнать больше об автомобиле (used)
            USED_CAR_CONTROLLER: '/ajax/forms/getCarUsedInfoForm/',
            //6 Задать вопрос (FAQ)
            FAQ_CONTROLLER: '/ajax/faq/getFaqForm/',
            //7 Форма обратной связи на главной
            FEEDBACK_ROOT_CONTROLLER: '/ajax/forms/getFeedbackRootForm/',
            //8 Форма тех обслуживания
            TECHNICAL_FORM_CONTROLLER: '/ajax/forms/getTechnicalForm/',
            // Cогласие на обработку персональных данных
            AGREE_CONTROLLER: '/ajax/forms/getAgree/'
        },
        selectors: {
            FORM_SELECT: 'select.form-control',
            //1 Тест-драйв
            TEST_DRIVE_SELECTOR: '#test_drive_place',
            TEST_DRIVE_MODEL_SELECTOR: 'select[name="model"]',
            //3 Обратная связь
            FEEDBACK_SELECTOR: '#feedback_place',
            //4 Индивидуальное предложение
            INDIVIDUAL_SELECTOR: '#individual_place',
            //6 Задать вопрос (FAQ)
            ESTIMATION_SELECTOR: '#estimation_place',

            FW_WIN_SELECTOR: '.fw_win_content',
            FORM_FEEDBACK_ROOT: '.feedback-form-root',
            TECHNICAL_FORM: '#service_order_form_place',
        },
        title: {
            TEST_DRIVE_TITLE: 'Записаться на тест-драйв',
            CREDIT_CALC_TITLE: 'Расчет кредита',
            MAKE_REQUEST_FORM_TITLE: 'Заявка на лизинг',
            FEEDBACK_TITLE: 'Обратная связь',
            INDIVIDUAL_TITLE: 'Запросить индивидуальное предложение',
            USED_CAR_TITLE: 'Запрос подробной информации',
            ESTIMATION_TITLE: 'Оцените свой автомобиль'
        },
        immediately: function () {
            $('.open-form').click(function (e) {
                _FORMS.openForm(this);
                fw_MAMMON.preventHref(e);
            })
            $('.testdrive-form').click(function (e) {
                _FORMS.openTestDrive(this);
                fw_MAMMON.preventHref(e);
            });
            $('.credit-form').click(function (e) {
                _FORMS.openCreditCalc();
                fw_MAMMON.preventHref(e);
            });

            $('.make-request-form').click(function (e) {
                let brand_code = JSON.stringify($(this).data('brand')),
                    model_code = JSON.stringify($(this).data('model')),
                    is_commercial = ($(this).data('commercial'));
                _FORMS.openMakeRequestForm(brand_code, model_code, is_commercial);
                fw_MAMMON.preventHref(e);
            });

            $('.feedback-form').click(function (event) {
                var link = $(this);
                _FORMS.openFeedBack(link);
                fw_MAMMON.preventHref(event);
            });
            $('.individual-form').click(function (e) {
                _FORMS.openIndividual();
                fw_MAMMON.preventHref(e);
            });
            $('.used-car-form').click(function (e) {
                _FORMS.openCarUsedInfoForm();
                fw_MAMMON.preventHref(e);
            });
            $('.faq-form').click(function (e) {
                _FORMS.openFaqForm();
                fw_MAMMON.preventHref(e);
            });
            $('.feedback-form-root-btn').click(function (e) {
                _FORMS.openFeedbackRootForm();
                fw_MAMMON.preventHref(e);
            });
            if ($(_FORMS.selectors.TEST_DRIVE_SELECTOR).length) {
                _FORMS.loadTestDrive(_FORMS.selectors.TEST_DRIVE_SELECTOR);
            }
            if ($(_FORMS.selectors.FEEDBACK_SELECTOR).length) {
                _FORMS.loadFeedBack(_FORMS.selectors.FEEDBACK_SELECTOR);
            }
            if ($(_FORMS.selectors.ESTIMATION_SELECTOR).length) {
                _FORMS.loadPageEstimationForm(_FORMS.selectors.ESTIMATION_SELECTOR);
            }
            if ($(_FORMS.selectors.INDIVIDUAL_SELECTOR).length) {
                _FORMS.loadIndividual(_FORMS.selectors.INDIVIDUAL_SELECTOR, false);
            }
            if ($(_FORMS.selectors.FORM_FEEDBACK_ROOT).length) {
                _FORMS.loadFeedbackRoot(_FORMS.selectors.FORM_FEEDBACK_ROOT);
            }
            if ($(_FORMS.selectors.TECHNICAL_FORM).length) {
                _FORMS.loadTechnicalForm(_FORMS.selectors.TECHNICAL_FORM);
            }
        },
        openForm: function (link) {
            fw_Win_active = fw_WIN.create({
                lock_page: false,
                block_mode: true,
                width: 790,
                offset: 0,
                content: '',
                title: $(link).data('title'),
                container_class: WIN_CONTAINER_CLASS,
                content_class: WIN_CONTENT_CLASS,
                without_padding: true
            });
            var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
            _FORMS.loadForm(to, link);
        },
        loadForm: function (to, link, code = null, brand_code = null, model_code = null, is_commercial = false) {
            fw_MOJAX.send({
                url: _FORMS.controllers.GET_FORM_CONTROLLER,
                data: {code: code ?? $(link).data('form')},
                to: to,
                overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
                overlay_to: to,
                user_success: function () {
                    initFormElements();
                    initAgreeWin();
                    if (this.data.code !== 'feedback') {
                        _FORMS.loadEstimationModels(brand_code, model_code, is_commercial);
                    }
                }
            });
        },


        openTestDrive: function (obj) {
            fw_Win_active = fw_WIN.create({
                lock_page: false,
                block_mode: true,
                offset: 0,
                content: '',
                title: _FORMS.title.TEST_DRIVE_TITLE,
                container_class: "",
                content_class: WIN_CONTENT_CLASS,
                without_padding: true
            });
            var href = window.location.pathname;
            link_array = $(obj).attr("href");
            link_array = (link_array) ? link_array.split('#') : [];
            var model_code = (link_array.length > 1) ? link_array[1] : '';
            var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
            _FORMS.loadTestDrive(to, model_code);
        },
        loadTestDrive: function (to, model_code) {
            fw_MOJAX.send({
                url: _FORMS.controllers.TEST_DRIVE_CONTROLLER,
                data: {model_code: model_code},
                to: to,
                overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
                overlay_to: to,
                user_success: function (json_data, user_data) {
                    initIkSelectForm();
                    _FORMS.initTimePicker();
                    _FORMS.initModelSelectAction(to);
                    _FORMS.selectModelInTestDriveForm(user_data.core_data.model_code, to);
                }
            });
        },
        loadTechnicalForm: function (model_code) {
            fw_MOJAX.send({
                url: _FORMS.controllers.TECHNICAL_FORM_CONTROLLER,
                to: _FORMS.selectors.TECHNICAL_FORM,
                user_success: function (json_data, user_data) {
                    initFormElements();
                    initAgreeWin();
                }
            });
        },
        openAgreeWin: function () {
            agree_window = fw_WIN.create({
                lock_page: false,
                block_mode: true,
                offset: 0,
                content: '',
                width: 790,
                title: "<h1>Согласие на обратную коммуникацию</h1>",
                container_class: WIN_CONTAINER_CLASS+' -agree',
                content_class: WIN_CONTENT_CLASS,
                without_padding: true
            });
            fw_MOJAX.send({
                to: agree_window.content,
                url: _FORMS.controllers.AGREE_CONTROLLER,
                overlay_to: agree_window.content,
                overlay_mode: fw_MOJAX.BLOCK_OVERLAY
            });
        },
        selectModelInTestDriveForm: function (model_code, to) {
            var model_select = $(_FORMS.selectors.TEST_DRIVE_MODEL_SELECTOR);
            model_code
                ? model_select.ikSelect('select', model_select.find('option[data-model-code="' + model_code + '"]').index(), true)
                : model_select.ikSelect('select', model_select.find('option:first-of-type').index(), true);
            _FORMS.setModelSelectImage(to);
        },
        setModelSelectImage: function (parent) {
            var model_id = $(parent).find('select[name=model] option:selected').data('id');
            $(parent).find('.model_info_item').hide();
            $(parent).find('.model_info_item[data-model-id=' + model_id + '] ').show();
            $(parent).find('input[name=image]').val($('.model_info_item[data-model-id=' + model_id + '] img').attr('src'));
        },
        initModelSelectAction: function (to) {
            $('select[name=model]').change(function () {
                _FORMS.setModelSelectImage(to);
            });
            _FORMS.setModelSelectImage(to);
        },
        initTimePicker: function () {
            var inst = $('input[name=time]');
            var timePicker = inst.timepicker({
                defaultTime: "13:00",
                useBackground: false,
                countColumn: 1,
                orientation: 't',
                beforeShow: function () {
                    // $('.ftype_time input').parent().append($('.container_timepicker'));
                }
            });
            var icon = _FORMS.getInputIcon(inst);
            var date = new Date();
            date.setDate(date.getDate() + 1);
            if (icon.length) {
                icon.click(function (e) {
                    !timePicker.isVisible() ? timePicker.show() : timePicker.hide();
                    fw_MAMMON.preventDefaultSafe(e);
                });
            }
            _FORMS.initDatePicker(date, timePicker);
        },
        initDatePicker: function (default_date, timePicker) {
            var inst = $('input[name=date]');
            var icon = _FORMS.getInputIcon(inst);
            inst.datepicker({
                inline: true,
                minDate: new Date(),
                beforeShow: function (input, obj) {
                    // $(input).after($(input).datepicker('widget'));
                    // $(input).addClass('active');
                },
                onClose: function (input, obj) {
                    $(obj.input).removeClass('active');
                },
                onSelect: function (dateText, inst) {
                    $(this).trigger('change');
                    if (!timePicker) return true;
                    var selected_date = Date.parse(dateText.split('.').reverse().join('.')),
                        date_obj = new Date(),
                        current_date = Date.parse((date_obj.getMonth() + 1) + '/' + date_obj.getDate() + '/' + date_obj.getFullYear());
                    if (selected_date === current_date) {
                        var minutes = date_obj.getMinutes(),
                            time = (date_obj.getHours() + 1) + ':' + (minutes < 10 ? '0' : '') + minutes;
                        timePicker.disabledUntilTime(time);
                    } else {
                        timePicker.clearDisabled();
                    }
                    return true;
                }
            });
            if (icon.length) {
                icon.click(function (e) {
                    if (inst.datepicker('widget').is(':hidden')) {
                        inst.datepicker('show');
                    }
                    fw_MAMMON.preventDefaultSafe(e);
                });
            }

            if (default_date != 'undefined') {
                inst.datepicker('setDate', default_date);
                inst.datepicker("option", "dateFormat", "dd.mm.yy");
            }

            if (fw_MAMMON.isMobileDevice()) {
                $('.ftype_date input,.ftype_time input').focus(function () {
                    $(this).blur();
                });
            }
        },
        getInputIcon: function (input) {
            return input.parent().find('.icon-input');
        },
        openCreditCalc: function () {
            fw_Win_active = fw_WIN.create({
                lock_page: true,
                block_mode: true,
                offset: 0,
                content: '',
                title: _FORMS.title.CREDIT_CALC_TITLE,
                container_class: "",
                content_class: WIN_CONTENT_CLASS,
                without_padding: true
            });
            var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
            _FORMS.loadCreditCalc(to);
        },
        openMakeRequestForm: function (brand_code, model_code, is_commercial) {
            fw_Win_active = fw_WIN.create({
                lock_page: true,
                block_mode: true,
                width: 900,
                offset: 0,
                content: '',
                title: _FORMS.title.MAKE_REQUEST_FORM_TITLE,
                container_class: WIN_CONTAINER_CLASS+' -loadMakeRequestForm',
                content_class: WIN_CONTENT_CLASS
            });
            let to = $(_FORMS.selectors.FW_WIN_SELECTOR);
            _FORMS.loadMakeRequestForm(to, brand_code, model_code, is_commercial);
        },
        loadMakeRequestForm: function (to, brand_code, model_code, is_commercial) {
            fw_MOJAX.send({
                url: _FORMS.controllers.MAKE_REQUEST_FORM_CONTROLLER,
                data: {brand_code: brand_code, model_code: model_code, is_commercial: is_commercial},
                to: to,
                overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
                overlay_to: to,
                user_success: function () {

                }
            });
        },
        loadCreditCalc: function (to) {
            fw_MOJAX.send({
                url: _FORMS.controllers.CREDIT_CALC_CONTROLLER,
                to: to,
                overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
                overlay_to: to,
                user_success: function () {
                    _CREDIC_CALC.initCreditCalc({calcButtonSelectText: '#calc_credit_button'});
                    $(to).find('input[name=model]').val(model_name);
                    $(to).find('input[name=integration]').val(model_name);
                    $(to).find('input[name=price]').val(price);
                    $(to).find('.credit_form_car_info .car_name').text(model_name);
                    $(to).find('.credit_form_car_info .car_info_credit_price .numeral').html(text_price);
                    $(to).find('.credit_form_car_info .car_info_image').attr('src', model_image);
                }
            });
        },
        openFeedBack: function (link) {
            fw_Win_active = fw_WIN.create({
                lock_page: false,
                block_mode: true,
                width: 790,
                offset: 0,
                content: '',
                title: _FORMS.title.FEEDBACK_TITLE,
                container_class: WIN_CONTAINER_CLASS,
                content_class: WIN_CONTENT_CLASS,
                without_padding: true
            });
            var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
            _FORMS.loadFeedBack(to, link);
        },
        loadFeedBack: function (to, link) {
            fw_MOJAX.send({
                url: _FORMS.controllers.FEEDBACK_CONTROLLER,
                data: {form: link.data('form')},
                to: to,
                overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
                overlay_to: to,
                user_success: function () {
                    initFormElements();
                    initAgreeWin();
                    selectCurrentSalon(to, link);
                }
            });
        },
        openIndividual: function () {
            fw_Win_active = fw_WIN.create({
                lock_page: false,
                block_mode: true,
                offset: 0,
                content: '',
                title: _FORMS.title.INDIVIDUAL_TITLE,
                container_class: "",
                content_class: WIN_CONTENT_CLASS,
                without_padding: true
            }, {});
            var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
            _FORMS.loadIndividual(to, true);
        },
        loadIndividual: function (to, isForm) {
            fw_MOJAX.send({
                url: _FORMS.controllers.INDIVIDUAL_CONTROLLER,
                to: to,
                overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
                overlay_to: to,
                user_success: function () {
                    initIkSelectForm();
                    if (isForm) {
                        putIndividualInfo(_FORMS.selectors.FW_WIN_SELECTOR);
                        randomOidTerritory(_FORMS.selectors.FW_WIN_SELECTOR);
                    } else {
                        randomOidTerritory(_FORMS.selectors.INDIVIDUAL_SELECTOR);
                        $(_FORMS.selectors.INDIVIDUAL_SELECTOR + ' .form_inner_wrapper').prepend('<h2>Запросить индивидуальное предложение</h2>');
                        $('.individual_form_info ').hide();
                        _FORMS.initModelSelectAction();
                    }
                }
            });
        },
        openCarUsedInfoForm: function () {
            fw_Win_active = fw_WIN.create({
                lock_page: false,
                block_mode: true,
                offset: 0,
                content: '',
                title: _FORMS.title.USED_CAR_TITLE,
                container_class: "",
                content_class: WIN_CONTENT_CLASS,
                without_padding: true
            });
            var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
            _FORMS.loadCarUsedInfoForm(to);
        },
        loadCarUsedInfoForm: function (to) {
            fw_MOJAX.send({
                url: _FORMS.controllers.USED_CAR_CONTROLLER,
                to: to,
                overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
                overlay_to: to,
                user_success: function () {
                    initGetCarInfoForm(to);
                }
            })
        },
        openFaqForm: function () {
            fw_Win_active = fw_WIN.create({
                lock_page: false,
                block_mode: true,
                offset: 0,
                content: '',
                width: 790,
                title: "Задать вопрос",
                container_class: WIN_CONTAINER_CLASS,
                content_class: WIN_CONTENT_CLASS,
                without_padding: true
            });
            var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
            _FORMS.loadFaqForm(to);
        },
        loadFaqForm: function (to) {
            fw_MOJAX.send({
                url: _FORMS.controllers.FAQ_CONTROLLER,
                to: to,
                overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
                overlay_to: to,
                user_success: function () {
                    initAgreeWin();
                    initFormElements();
                }
            });
        },
        loadPageEstimationForm: function (to) {
            fw_MOJAX.send({
                url: '/ajax/forms/getEstimationForm/',
                to: to,
                overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
                overlay_to: to,
                user_success: function () {
                    $(_FORMS.selectors.ESTIMATION_SELECTOR).find('.title').text(_FORMS.title.ESTIMATION_TITLE);
                    initIkSelectForm();
                    _FORMS.loadEstimationModels();
                }
            });
        },
        openFeedbackRootForm: function () {
            fw_Win_active = fw_WIN.create({
                lock_page: false,
                block_mode: true,
                offset: 0,
                content: '',
                width: 790,
                title: "Задать вопрос",
                container_class: WIN_CONTAINER_CLASS,
                content_class: WIN_CONTENT_CLASS,
                without_padding: true
            });
            var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
            _FORMS.loadFeedbackRoot(to);
        },
        loadFeedbackRoot: function (to) {
            fw_MOJAX.send({
                url: _FORMS.controllers.FEEDBACK_ROOT_CONTROLLER,
                to: to,
                overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
                overlay_to: to,
                user_success: function () {
                    initAgreeWin();
                    initFormElements();
                }
            });
        },
        loadEstimationModels: function (brand_code = null, model_code = null, is_commercial = false) {
            fw_MOJAX.send({
                url: "/ajax/cars/getBrandsListForSelect/",
                data: {is_fleet: $('input[name=is_fleet]:checked').val(), brand_code: brand_code, model_code: model_code},
                overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
                user_success: function (json_data) {
                    if (is_commercial) {
                        $('input[name=is_fleet][value="1"]').prop('checked', true);
                    }
                    reInitSelect('select[name=brand]', json_data.brands, true);

                    if (brand_code) {
                        setSelectizeValue(brand_code, 'brand');
                    }

                    $('select[name=brand]').off('change').on('change', function () {
                        let brand = $(this).val();
                        !brand ? clearSelect('select[name=model]') : _FORMS.changeModels(brand, model_code, 'model');
                    });


                    $('input[name=is_fleet]').on('change', function (brand) {
                        _FORMS.loadEstimationModels();
                    })
                }
            });
        },
        changeModels: function (brand, model_code = null, name = null) {
            fw_MOJAX.send({
                url: "/ajax/cars/getModelsListForSelect/",
                data: {brand: brand, is_fleet: $('input[name=is_fleet]:checked').val()},
                overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
                user_success: function (json_data) {
                    reInitSelect('select[name=model]', json_data.models, true, model_code, name);
                }
            });
        },
    };
    _FORMS.immediately();
    return _FORMS;
}();


function setSelectizeValue(code, name) {
    $(`select[name=${name}]`).each(function () {
        let modelSelectInstance = $(this)[0].selectize;
        modelSelectInstance.setValue(code);
    });
}

function initGetCarInfoForm(parent) {
    $(parent).find('input[name=model_name]').val(model_name);
    $(parent).find('input[name=car]').val(model_short_name);
    $(parent).find('input[name=price_clear]').val(price_clear);
    $(parent).find('input[name=TextYear]').val(year);
    $(parent).find('input[name=TextRun]').val(run);
    $(parent).find('input[name=territory_oid]').val(territory_oid);

    $(parent).find('.form-car-info-used .car-name').text(model_name);
    $(parent).find('.form-car-info-used .car-price').html(text_price);
    $(parent).find('.form-car-info-used .car-image').attr('src', model_img_src);
}

function selectCurrentSalon(parent, link) {
    if (link) {
        var salon_id = link.data('salon-id');
        if (!(salon_id == '' || salon_id == undefined)) {
            if ($(parent).find('select[name=territory_oid] option[value=' + salon_id + ']').length != 0) {
                $(parent).find('select[name=territory_oid]').val(salon_id);
                $(parent).find('select[name=territory_oid]').ikSelect('reset').ikSelect('redraw');
            }
        }
    }
}

function putIndividualInfo(parent) {
    $(parent).find('input[name=TextBrand]').val(brand_name_individ);
    $(parent).find('input[name=model_name]').val(model_name_simple_individ);
    $(parent).find('input[name=model_price]').val(price_individ);
    $(parent).find('input[name=order_num]').val(order_num_individ);
    $(parent).find('input[name=url]').val(location.href);
    $(parent).find('select[name=model] option:selected').removeAttr('selected');
    //TODO: проверить будет ли работать selected
    $(parent).find('select[name=model] option[data-model_id=' + model_id_individ + ']').attr('selected', 'selected');
    $(parent).find('select[name=model]').parents('.field_wrapper').hide();
    $(parent).find('.individual_form_info .car-name').text(model_name_individ);
    $(parent).find('.individual_form_info .car-price').html(text_price_individ);
    $(parent).find('.individual_form_info .car-image').attr('src', model_big_image_individ);
}

function randomOidTerritory(parent) {
    var countTerritory = $(parent).find('select[name=territory_oid] option').length;
    if (countTerritory > 1) {
        var rand = Math.floor(Math.random() * countTerritory);
        $(parent).find('select[name=territory_oid] option:selected').removeAttr('selected');
        var val = $(parent).find('select[name=territory_oid] option').eq(rand).val();
        $(parent).find('select[name=territory_oid]').val(val);
        $(parent).find('select[name=territory_oid]').ikSelect('reset').ikSelect('redraw');
    } else {
        $(parent).find('select[name=territory_oid]').parents('.field_wrapper ').hide();
    }
}


function initFormElements() {
    initSelect();
    initPhoneInput();
    _FORMS.initTimePicker();
    checkCustomRadioButtons();
}

function initSelect() {
    $('select.form-control').selectize({
        create: true,
        sortField: 'text',
        onInitialize: function () {
            var s = this;
            this.revertSettings.$children.each(function () {
                $.extend(s.options[this.value], $(this).data());
            });
        }
    });
}

function reInitSelect(elem, data, withCode, model_code = null, name = null) {
    if ($(elem).length > 0) {
        $(elem).each(function() {
            if (this.selectize) {
                this.selectize.destroy();
            }
            $(this).selectize({
                create: false,
                sortField: 'text',
                valueField: withCode ? 'code' : 'name',
                labelField: 'name',
                searchField: 'name',
                options: data
            });
        });
    }
    if (name && model_code) {
        setSelectizeValue(model_code, name)
    }
}


function clearSelect(elem) {
    if (elem) {
        $(elem).each(function() {
            if (this.selectize) {
                this.selectize.clear();
                this.selectize.clearOptions();
            }
        });
    }
}

function clearSelects() {
    $('.form-item select').each(function() {
        if (this.selectize) {
            this.selectize.clear();
            this.selectize.clearOptions();
            this.selectize.destroy();
        }
    });
}

function initPhoneInput() {
    $('input[name=phone], input[autocomplete=tel], input[type=phone]').inputmask("+7 (999) 999-99-99");
}

function checkCustomRadioButtons() {
    var form = document.querySelector('.form_style.win_form');
    if (!form) {
        return;
    }
    var submitButton = form.querySelector('.submit-button');
    if (!submitButton) {
        return;
    }
    var radioGroups = form.querySelectorAll('.radiobutton');
    if (radioGroups.length === 0) {
        return;
    }

    submitButton.addEventListener('click', function (event) {
        var formValid = true;

        radioGroups.forEach(function (radioGroup) {
            var radios = radioGroup.querySelectorAll('input[type="radio"]');
            var isChecked = Array.from(radios).some(function (radio) {
                return radio.checked;
            });

            var errorWrapper = radioGroup.querySelector('.radio_error_wrapper');

            if (!isChecked) {
                formValid = false;
                if (errorWrapper) {
                    errorWrapper.style.display = 'block';
                }
            } else {
                if (errorWrapper) {
                    errorWrapper.style.display = 'none';
                }
            }

            radios.forEach(function (radio) {
                radio.addEventListener('change', function () {
                    if (errorWrapper) {
                        errorWrapper.style.display = 'none';
                    }
                });
            });
        });
    });
}

function initIkSelectForm() {
    $(_FORMS.selectors.FORM_SELECT).ikSelect({
        autoWidth: false,
        dynamicWidth: false,
        ddFullWidth: false,
        customClass: 'IkSelect-forms',
        onHide: function (inst) {
            inst.$link.removeClass('with_dropped_list');
            if ($(inst.element).val() != '') inst.$linkText.addClass('filled');
            else inst.$linkText.removeClass('filled');
            $(inst.$link).removeClass('-open');
        },
        onShow: function (inst) {
            $(inst.$el).after(inst.$dropdown);
            inst.$link.addClass('with_dropped_list');
            $(inst.$link).addClass('-open');
        }
    });

}

function initAgreeWin() {
    $('.agree_personal_data').off('click').on('click', function (e) {
        _FORMS.openAgreeWin();
        fw_MAMMON.preventHref(e);
    });
}
